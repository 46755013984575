<template>
  <ion-page>
    <app-header back="home" title="사용자 2차승인" />
    <ion-content :fullscreen="true">
      <template v-if="isConnected">
        <div id="container">
          <search-list
            :useSearch="false"
            :list="items"
            :limit="LIMIT"
            :next="next"
            v-on:@onSearch="onSearch"
            v-on:@doRefresh="doRefresh"
            v-on:@pushData="pushData"
            v-on:@setInitPage="setInitPage"
            v-on:@getNextPage="getNextPage"
            v-on:@itemClick="itemClick">
        <template #default="items">
          <ion-card>
              <ion-grid class="detail-grid">
                  <ion-grid class="detail-grid">
                    <ion-row class="first-row">
                      <ion-col class="detail-title" size="4">아이디</ion-col>
                      <ion-col><ion-label color ="tertiary">{{ items.item.userId }}</ion-label></ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col class="detail-title" size="4"> 회사명 </ion-col>
                      <ion-col><ion-label color ="tertiary">{{ items.item.userName }}</ion-label></ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col class="detail-title" size="4"> 사업자번호 </ion-col>
                      <ion-col><ion-label color ="dark">{{ items.item.userSano }}</ion-label></ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col class="detail-title" size="4"> 사용자구분 </ion-col>
                      <ion-col><ion-label color ="dark">{{ items.item.gubn_nm }}</ion-label></ion-col>
                    </ion-row>
                  </ion-grid>
              </ion-grid>
            </ion-card>  
          </template>    
        </search-list>
      </div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonLabel,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, inject, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useManage,UserAppr } from '@/api/services/manage';
import { toast } from '@/utils/toast';
import { Emitter, EventType } from 'mitt';
import { setDateFormat } from '@/utils/dateFormatter';
import { actionSheet } from '@/utils/actionSheet';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonLabel
  },
  setup() {
    const {
      isConnected,
      LIMIT,
      next,
      items,
      setInitPage,
      getNextPage,
      fetchFinalDatas,
      fetchNextDatas,
      refreshDatas,
      updateData
    } = useManage();

    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '사용자2차승인');
    });


    async function fetchList() {
      await fetchFinalDatas();
    }
    async function fetchDatas() {
      fetchList();
    }
    onIonViewWillEnter(() => {
      fetchDatas();
    });

    async function onReConnect() {
      await fetchDatas();
    }
    async function onSearch() {
      setInitPage();
      await fetchDatas();
    }

    async function doRefresh() {
      await refreshDatas();
    }

    async function pushData() {
      await fetchNextDatas();
    }


    function itemClick(item: UserAppr) {
      actionSheet(
            '가입승인',
          [
            {
              text: '승인',
              cssClass: 'color-primary',
              handler: () => {
                updateData(item.userId);
                onSearch();
              }
            },
            {
              text: '닫기',
              role: 'cancel'
            }
            
          ]
        );

    }
    return {
      setDateFormat,
      isConnected, 
      LIMIT,
      next,
      items,
      onSearch,
      doRefresh,
      pushData,
      setInitPage,
      getNextPage,
      onReConnect,
      itemClick
    };
  }
});
</script>

<style scoped>
#container {
  margin: 0 auto;
  max-width: 800px;
}
ion-card-title {
  font-size: 1.2em;
}
ion-label {
  font-size: 1em;
}
ion-grid {
  font-size: 16px;
}
ion-row {
  align-items: center;
}
ion-grid.detail-grid ion-row {
  border-bottom: 1px solid #eee;
  
}
ion-grid.detail-grid ion-col {
  padding: 0.4em;
  font-size: 0.9em;
}
.first-row {
  border-top: 1px solid #eee;
}
.detail-title {
  background-color: #FFFFFF;
  color: rgb(31, 31, 31);
  border-right: 1px solid #eee;
}
.content-wrap {
  padding: 0.5em;
}
ion-list ion-card {
  padding: 0.5em;
}
</style>
