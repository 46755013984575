
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonLabel,
  onIonViewWillEnter

} from '@ionic/vue';
import { defineComponent, inject, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useManage,UserAppr } from '@/api/services/manage';
import { toast } from '@/utils/toast';
import { Emitter, EventType } from 'mitt';
import { setDateFormat } from '@/utils/dateFormatter';
import { actionSheet } from '@/utils/actionSheet';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonLabel
  },
  setup() {
    const {
      isConnected,
      LIMIT,
      next,
      items,
      setInitPage,
      getNextPage,
      fetchFinalDatas,
      fetchNextDatas,
      refreshDatas,
      updateData
    } = useManage();

    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '사용자2차승인');
    });


    async function fetchList() {
      await fetchFinalDatas();
    }
    async function fetchDatas() {
      fetchList();
    }
    onIonViewWillEnter(() => {
      fetchDatas();
    });

    async function onReConnect() {
      await fetchDatas();
    }
    async function onSearch() {
      setInitPage();
      await fetchDatas();
    }

    async function doRefresh() {
      await refreshDatas();
    }

    async function pushData() {
      await fetchNextDatas();
    }


    function itemClick(item: UserAppr) {
      actionSheet(
            '가입승인',
          [
            {
              text: '승인',
              cssClass: 'color-primary',
              handler: () => {
                updateData(item.userId);
                onSearch();
              }
            },
            {
              text: '닫기',
              role: 'cancel'
            }
            
          ]
        );

    }
    return {
      setDateFormat,
      isConnected, 
      LIMIT,
      next,
      items,
      onSearch,
      doRefresh,
      pushData,
      setInitPage,
      getNextPage,
      onReConnect,
      itemClick
    };
  }
});
