import { actionSheetController } from '@ionic/vue';

export async function actionSheet(header: string, buttons: any, subHeader?: string) {
  const actionSheet = await actionSheetController.create({
    header: header,
    buttons: buttons,
    subHeader: subHeader,
    backdropDismiss: true
  });

  window.addEventListener('popstate', function(e) {
    const state = e.state;
    if (state) {
      if (actionSheet) {
        actionSheet.dismiss();
      }
    }
  });

  return await actionSheet.present();
}
